import * as React from 'react';
import ScheduleIcon from '@mui/icons-material/Schedule';
import CookTimeIcon from '@mui/icons-material/Whatshot';
import EditIcon from '@mui/icons-material/ModeEdit'

import './RecipePage.css'
import { useNavigate, useLocation} from "react-router-dom";
import { compose } from "redux";
import { connect} from "react-redux";
import {fetchByIdAsync} from "../reducers/RecipesReducer";
import {withParams} from "../../views/RouterHooks";
import {Fab, Skeleton, Toolbar, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";

class RecipePage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            recipe: props.recipe
        }
    }

    async componentDidMount() {

        let recipe = this.props.recipe
        if (!recipe) this.props.fetchById(this.props.params.id)
    }

    render() {
        let logo = this.renderImage(this.props.recipe?.medias ?? [], 'DISH_IMAGE');
        let recipeImage = this.renderImage(this.props.recipe?.medias ?? [], 'RECIPE_IMAGE');
        let preparationTime = this.renderTime(this.props.recipe?.preparationTime)
        let cookingTime = this.renderTime(this.props.recipe?.cookingTime)

        return (
            <div className="page page-content in-column">
                {this.props.recipe ? (
                    <div className="recipe-page">
                        <div className="recipe-page-content">
                            <div className="recipe-page-description">
                                <RecipeHeader recipe={this.props.recipe}/>
                                { this.props.recipe?.formType === 'DETAILED' ? (
                                    <div>
                                        <RecipeInfo preparationTime={preparationTime} cookingTime={cookingTime}/>
                                        <RecipeList title="Les ingrédients" type="ingredients"
                                                    items={this.props.recipe?.ingredients}/>
                                        <RecipeList title="Les étapes" type="steps"
                                                    items={this.props.recipe?.steps}/>
                                    </div>
                                ) : null }
                            </div>
                            { logo && (
                                <div className="recipe-page-image">
                                    <img className="recipe-image" src={logo} alt={this.props.recipe.name}/>
                                </div>
                            )}

                        </div>
                        {recipeImage && (
                            <div>
                                <h4>Les photos</h4>
                                <a href="#img1"><img className="thumbnail" src={recipeImage}/></a>
                            </div>
                        )}
                    </div>) : <RecipePageSkeleton/>
                }
            </div>
        );
    }

    renderImage(medias, type) {

        if(medias) {
            return medias.filter(r => r.type === type)[0]
                ? medias.filter(r => r.type === type)[0].url
                : null
        }
    }

    renderTime(timeInMinutes) {

        if (!timeInMinutes) return;

        let hours = (timeInMinutes / 60);
        let rhours = Math.floor(hours);
        let minutes = (hours - rhours) * 60;
        let rminutes = Math.round(minutes);

        if (rhours === 0) {
            return `${rminutes} min`
        } else if (rminutes === 0) {
            return `${rhours}h`
        } else {
            return `${rhours}h ${rminutes} min`
        }
    }
}

function RecipeInfo(props) {

    let preparationTime = props.preparationTime ? (
        <div style={{display: "flex", alignItems: "center"}}>
            <ScheduleIcon/> {` ${props.preparationTime}`}
        </div>) : null

    let cookingTime = props.cookingTime ? (
        <div style={{display: "flex", alignItems: "center"}}>
            <CookTimeIcon/> {` ${props.cookingTime}`}
        </div>) : null

    return <div style={{display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}>
        {preparationTime}
        {cookingTime}
    </div>;
}

function RecipePageSkeleton() {

    let list = []
    for (let i = 0; i < 3; i++) {
        list.push(<Skeleton key={i} variant="text" /> )
    }

    return (
        <div className="recipe-page-content">
            <div  className="recipe-page-description">
                <Typography variant="h1"><Skeleton variant="text" /></Typography>
                <div>
                    <Skeleton variant="text" />
                    <br />
                    <h4>Les ingrédients</h4>
                    {list}
                    <h4>Les étapes</h4>
                    {list}
                </div>
            </div>

            <div className="recipe-page-image">
                <Skeleton variant="rectangular" width={500} height={440}/>
            </div>

        </div>
    )
}

function RecipeHeader(props) {

    let navigate = useNavigate()
    const EditFabIcon = styled(Fab)({
        position: 'absolute',
        top: 60,
        right: 120,
        margin: '0 auto',
    });

    const createRecipe = () => navigate(`/recipes/${props.recipe.id}/edit`)

    let title = props.recipe
        ? (
            <div>
                <EditFabIcon  aria-label="add" onClick={createRecipe}>
                    <EditIcon />
                </EditFabIcon>
                <h1 itemProp="name">
                    {props.recipe.name}

                </h1>

            </div>
        ) : <Skeleton variant="text" />

    return (
        <div>
            {title}
            { props.recipe && <p itemProp="description">{props.recipe.description}</p>}
        </div>
    )
}

function RecipeList(props) {

    if (!props.items || props.items.length === 0) return

    return (
        <div>
            <h4>{props.title}</h4>
            <ul>{props.items.map((item, i) =>
                <li key={`${props.type}-${i}`}>{item}</li>)}
            </ul>
        </div>
    )
}

const mapStateToProps = (state, props) => {
    return {
        recipe: state.recipes.recipes.find(r => r.id === props.params.id),
        loading: state.recipes.loading
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchById: (id) => { dispatch(fetchByIdAsync(id))}
    }
};
export default compose(withParams, connect(mapStateToProps, mapDispatchToProps))(RecipePage)

