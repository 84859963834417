import axios from 'axios'
import RecipesApi from './RecipesApi'

axios.defaults.baseURL =  process.env.REACT_APP_BACKEND_HOST;

axios.interceptors.request.use(function(request) {
    if (request.baseURL === process.env.REACT_APP_BACKEND_HOST) {
        request.headers = Object.assign(request.headers, {
            'Access-Control-Allow-Origin': '*'
        })
    }

    return request
}, function(error) {
    return Promise.reject(error)
})

export {
    RecipesApi,
}
