import React, {Component} from 'react';

import {compose} from 'redux';
import {connect} from 'react-redux';
import Field from '../../components/forms/Field'
import _ from 'underscore'
import ImageLoader from '../../components/forms/ImageLoader'
import axios from 'axios'
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'

import './RecipeForm.css'
import {fetchByIdAsync, saveRecipeAsync} from "../reducers/RecipesReducer.ts";
import {Fab, Link, List, ListItem, ListItemText} from "@mui/material";
import Block from "../../components/Blox";
import IconButton from "@mui/material/IconButton";
import {LoadingButton} from "@mui/lab";
import {withParams} from "../../views/RouterHooks";
import {styled} from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

class RecipeForm extends Component {

    constructor(props) {
        super(props)
        this.state = {
            recipeId: this.props.params.id,
            isInPreviewMode: false,
            recipe: {
                name: "",
                madeBy: "",
                description: "",
                cookingTime: 0,
                preparationTime: 0,
                creationDate: new Date(),
                updateDate: null,
                ingredients: [],
                steps: [],
                medias: []
            },
            ingredient: "",
            step: "",
            stepToEdit: null,
            uploading: false,
            error: false,
            fieldInError: [],
            currentFormStepIndex: 0,
            isDetailedForm: !!this.props.params.id
        };
    }

    componentDidMount() {

        if (this.props.params.id) {
            let recipe = this.props.recipe ?? {}
            this.setState({
                recipe: recipe,
                recipeId: this.props.params.id,
                initialRecipe: null,
                isInPreviewMode: false,
            })
        }
    }

    unsavedChange() {
       return !_.isEqual(this.props.recipe, this.state.recipe)
    }

    render() {

        let isLoading = null// this.renderLoadingIcon(this.state.uploading)

        let error = this.renderError();

        let formStep =  this.state.isDetailedForm
            ? this.renderFormStep(FORM_STEPS, this.state.currentFormStepIndex)
            : this.renderFormStep(FAST_FORM_STEPS, 0)

        let hasContentChanged = this.unsavedChange()

        const EditFabIcon = styled(Fab)({
            position: 'absolute',
            top: 60,
            right: 120,
            margin: '0 auto',
        });

        return (
            <div id="recipe-form" className="page page-content in-column">
                <EditFabIcon  aria-label="add" onClick={() => this.props.navigate(-1)}>
                    <CloseIcon />
                </EditFabIcon>
                <h1>Qu'est ce que tu vas nous préparer de bon aujourd'hui ?</h1>
                <div>
                    <div className="Form-step-container">
                        <div className="Form-steps">
                            {formStep}
                        </div>
                        {
                            this.state.isDetailedForm
                                ? (<div className="Form-step-action-button align-end">
                                    {error}
                                    <Button color="inherit" variant="text" disabled={!hasContentChanged}
                                            onClick={this.cancelChange.bind(this)}>Annuler</Button>
                                    <LoadingButton color="primary" variant="contained" disableElevation
                                            loading={this.state.uploading}
                                            disabled={!hasContentChanged}
                                            onClick={this.saveRecipe.bind(this)}>Enregistrer</LoadingButton>
                                </div>)
                                : (<div className="Form-step-action-button">
                                    {error}
                                    <div>
                                        <Link href="src/recipes/views/RecipeForm#"
                                              onClick={this.onAddDetailedRecipe.bind(this)}>
                                            Ajouter une recette détaillée
                                        </Link>
                                    </div>
                                    <div>
                                        <LoadingButton color="primary" variant="contained" disableElevation
                                                       loading={this.state.uploading}
                                                       disabled={this.state.uploading}
                                                       onClick={this.saveRecipe.bind(this)}>Enregister</LoadingButton>
                                    </div>
                                </div>)
                        }
                    </div>
                </div>
            </div>
        );
    }

    onChange(type, newValue) {
        let tmp = JSON.parse(JSON.stringify(this.state))

        if (_.isString(tmp[type])) {
            tmp[type] = newValue;
        }
        else {
            tmp.recipe[type] = newValue;
        }
        tmp.error = false;
        tmp.fieldInError = _.filter(this.state.fieldInError, n => n !== type)
        this.setState(tmp)
    }

    isFormValid(recipe) {

        let fieldsInError = []

        let onlyNumbers = new RegExp(/^\d+$/)

        if (recipe.name === "") fieldsInError.push("name")
        if (this.state.isDetailedForm) {
            if (recipe.madeBy === "") fieldsInError.push("madeBy")
            if (!onlyNumbers.test(recipe.preparationTime)) fieldsInError.push("preparationTime")
            if (!onlyNumbers.test(recipe.cookingTime)) fieldsInError.push("cookingTime")
        }

        this.setState({fieldInError: fieldsInError, error: fieldsInError.length > 0})
        return fieldsInError.length === 0
    }

    cancelChange() {
        this.setState({recipe: this.props.recipe})
    }

    async saveRecipe() {

        this.setState({uploading: true});
        let recipe = this.state.recipe

        console.log(recipe.madeBy)
        if (this.isFormValid(recipe)) {

            let images = await this.getImagesFormData(recipe.id)
            let result = await this.props.saveRecipe({recipe, images})

            this.setState({uploading: false})
            switch (result.type) {
                case "recipes/saveRecipe/rejected":
                    this.error = "Big erreur"
                    break;
                default: await this.props.navigate('/recipes/' + result?.payload?.id )
            }

        } else this.setState({uploading: false});
    }

    async getImagesFormData(recipeId) {
        let logo = await this.uploadImage(recipeId, 'recipeLogo');
        let image = await this.uploadImage(recipeId, 'recipeImage');

        let results = []

        if (logo) results.push(logo)
        if (image) results.push(image)
        return results
    }

    async uploadImage(recipeId, fileDOMId) {
        let formData = new FormData();
        let file = this.getImage(fileDOMId);

        if (file) {
            let size = fileDOMId === 'recipeImage' ? 800 : 400
            let resizedImage = await this.resizeImage(file, size);
            formData.append('file', resizedImage, file.name);
            formData.append('fileName', `${recipeId}_${file.name}`)
            formData.append('mimeType', file.type);

            let type = fileDOMId === 'recipeLogo' ? 'DISH_IMAGE' : 'RECIPE_IMAGE'
            return Object.assign({}, {recipeId, type, formData})

        } else return null
    }

    getImage(fileDOMId) {
        let imageField = document.getElementById(fileDOMId);
        return imageField ? imageField.files[0] : null;
    }

    drawThumbnail(filePath, size) {

        return new Promise(((resolve, reject) => {

            const image = new Image()
            image.src = filePath

            image.onload = function () {
                let maxWidth = size,
                    maxHeight = size,
                    imageWidth = image.width,
                    imageHeight = image.height

                if (imageWidth > imageHeight) {
                    if (imageWidth > maxWidth) {
                        imageHeight *= maxWidth / imageWidth
                        imageWidth = maxWidth
                    }
                } else {
                    if (imageHeight > maxHeight) {
                        imageWidth *= maxHeight / imageHeight
                        imageHeight = maxHeight
                    }
                }

                const canvas = document.createElement('canvas')
                canvas.width = imageWidth
                canvas.height = imageHeight
                image.width = imageWidth
                image.height = imageHeight
                const ctx = canvas.getContext('2d')
                ctx.drawImage(this, 0, 0, imageWidth, imageHeight)

                canvas.toBlob(function (blob) {
                    resolve(blob)
                }, 'image/jpeg', 0.95)

            }
        }))
    }

    resizeImage(file, size) {
        const reader = new FileReader()

        return new Promise(((resolve, reject) => {
            let url = ""
            reader.onload = function (event) {
                url = this.drawThumbnail(event.target.result, size)
                resolve(url)
            }.bind(this)

            reader.readAsDataURL(file)

        }))

    }

    onDeleteItem(elt, type, e) {
        e.preventDefault();
        let tmp = _.clone(this.state[type])
        const index = tmp.indexOf(elt);
        if (index > -1) {
            tmp.splice(index, 1);
        }
        let tmpState = _.clone(this.state)
        tmpState[type] = tmp

        this.setState(tmpState)
    }

    renderItemList(currentField) {

        let recipeItems = this.state.recipe[currentField.name + "s"].map((item, i) => {
            const deleteButton = (
                <IconButton edge="end" aria-label="delete"
                            onClick={this.deleteListItem.bind(this, currentField.name, i)}>
                    <DeleteIcon />
                </IconButton>)

            return <ListItem secondaryAction={deleteButton} key={`ingredient-${i}`}>
                <ListItemText primary={item} />
            </ListItem>
        });

        const addButton = <IconButton
            edge="end" arial-label="Ajouter"
            onClick={this.addListItem.bind(this, currentField.name)}>
            <AddIcon />
        </IconButton>


        const addNewItem = (<ListItem secondaryAction={addButton} key={"add-button"}>
            <Field name={currentField.name}
                   style={{width: '75%'}}
                   variant="standard" dense fullWidth
                   label={currentField.description}
                   placeholder={currentField.description}
                   value={this.state[currentField.name]}
                   onKeyDown={this.addListItem.bind(this, currentField.name)}
                   onChange={this.onChange.bind(this)}
                   errorText="Vous devez ajouter au moins 1 étape"
                   isInError={this.isFieldInError(currentField.name)}
                   floatingLabelText={currentField.description}/>
        </ListItem>)

        recipeItems.push(addNewItem)

        return <List dense>{recipeItems}</List>
    }

    addListItem(itemName, e) {
        if (e.key === 'Enter' || e.type === 'click') {
            if (!this.state[itemName] || this.state[itemName] === "") return;

            let item = this.state[itemName];
            let tmp = _.clone(this.state.recipe[itemName + "s"])
            tmp.push(item)

            let newState = Object.assign({}, {recipe: JSON.parse(JSON.stringify(this.state.recipe))})
            newState.recipe[itemName + "s"] = tmp
            newState[itemName] = ""
            this.setState(newState)
        }
    }

    deleteListItem(itemName, index, e) {
        e.preventDefault()
        let recipe = JSON.parse(JSON.stringify(this.state.recipe))
        recipe[itemName + "s"].splice(index, 1);
        this.setState({recipe: recipe})
    }

    onAddDetailedRecipe(e) {
        e.preventDefault()
        this.setState({isDetailedForm: true})
    }

    renderError() {
        return this.state.error
            ? <div className="error-block">
                <div className="">Vous avez un ou plusieurs champs en erreur.</div>
            </div>
            : null
    }

    isFieldInError(type) {
        return this.state.fieldInError.indexOf(type) !== -1
    }

    computeField(currentField) {
        let fieldHtml = null
        if (currentField.inputType === 'string') {
            fieldHtml = <Field name={currentField.name}
                               label={currentField.description}
                               placeholder={currentField.description}
                               errorText="Champ obligatoire"
                               value={this.state.recipe[currentField.name]}
                               isInError={this.isFieldInError(currentField.name)}
                               onChange={this.onChange.bind(this)} />

        } else if (currentField.inputType === 'numeric') {
            fieldHtml = <Field name={currentField.name}
                               label={currentField.description}
                               type="number"
                               value={this.state.recipe[currentField.name].toString()}
                               onChange={this.onChange.bind(this)}
                               errorText="Champ Obligatoire"
                               isInError={this.isFieldInError(currentField.name)} />
        } else if (currentField.inputType === 'list') {

            fieldHtml = (<div >
                <div className="field-list">
                    {this.renderItemList(currentField)}
                </div>
            </div>);
        } else if (currentField.inputType === 'photo') {

            let imageType = currentField.imageType
            let medias = this.state.recipe?.medias ?? []
            let stateElement = medias.find(m => m.type === imageType)

            fieldHtml = (<ImageLoader filePath={stateElement}
                           id={currentField.name}
                           label={currentField.description}
                           existingImage={this.findMediaUrl(this.state.recipe.medias, imageType)}
                           callback={this.onMediaChange.bind(this, currentField)}/>)
        }

        return <div className="Form-step" key={`Field-${currentField.name}`}>{fieldHtml}</div>
    }

    findMediaUrl(medias, type) {
        return (medias ?? []).find(m => m.type === type)?.url
    }

    onMediaChange(field, newValue) {
        let existingRecipe = JSON.parse(JSON.stringify(this.state.recipe))
        let media = Object.assign({type: field.imageType}, newValue)

        let existingMedias = existingRecipe?.medias ?? [];
        let index = existingMedias.findIndex(m => m.type === field.imageType)
        if (index === -1) existingMedias.push(media)
        else existingMedias[index] = media

        this.setState({recipe: Object.assign(existingRecipe, {
            medias: existingMedias
        })})
    }

    computeStepFields(currentStep) {
        let currentFields = ALL_FIELDS.filter((field) => currentStep.fields.indexOf(field.name) !== -1)
        return currentFields.map((currentField, i) => {
            return this.computeField(currentField)
        })
    }

    renderFormStep(formSteps) {
        return formSteps.map((step, i) => {
            let currentStep = formSteps[i]
            return (<Block title={step.name} style={{maxWidth: '900px', alignSelf: 'center'}} key={`form-step-${i}`}>
                {this.computeStepFields(currentStep)}
            </Block>)
        })
    }

    isMobile() {
        const width = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;

        return width < 737
    }
}

const ALL_FIELDS = [{
    name: 'name',
    description: 'Nom de la recette',
    inputType: 'string'
}, {
    name: 'madeBy',
    description: 'Concocté par',
    inputType: 'string'
}, {
    name: 'description',
    description: 'Ajouter une description courte de la recette',
    inputType: 'string'
}, {
    name: 'cookingTime',
    description: 'Temps de cuisson (en minutes)',
    inputType: 'numeric'
}, {
    name: 'preparationTime',
    description: 'Temps de préparation (en minutes)',
    inputType: 'numeric'
}, {
    name: 'ingredient',
    description: 'Ajouter un ingrédient',
    inputType: 'list'
}, {
    name: 'step',
    description: 'Ajouter les étapes de votre recette',
    inputType: 'list'
}, {
    name: 'recipeImage',
    description: 'Ajouter une photo de la recette',
    inputType: 'photo',
    imageType: 'RECIPE_IMAGE'
}, {
    name: 'recipeLogo',
    description: 'Ajouter une photo de votre plat',
    inputType: 'photo',
    imageType: 'DISH_IMAGE'
}]

const FORM_STEPS = [{
    name: "Descriptif de la recette",
    fields: ["name", "madeBy", "description", "cookingTime", "preparationTime"]
}, {
    name: 'Quels sont les ingrédients ?',
    fields: ["ingredient"]
}, {
    name: 'Indiquer les étapes',
    fields: ["step"]
}, {
    name: 'Ajouter des photos',
    fields: ["recipeLogo", "recipeImage"]
}]

const FAST_FORM_STEPS = [{
    name: "Fast form steps",
    fields: ["name", "madeBy", "recipeLogo", "recipeImage"]
}]

const mapStateToProps = (state, { params }) => {
    return {
        loading: state.recipes.loading,
        recipe: state.recipes.recipes.find(r => r.id === params.id)
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchById: (id) => { return dispatch(fetchByIdAsync(id)) },
        saveRecipe: (payload) => { return dispatch(saveRecipeAsync(payload)) },
    }
};

export default compose(withParams, connect(mapStateToProps, mapDispatchToProps))(RecipeForm);
