import axios from 'axios'
import {Media, MediaResponse, Recipe, UploadMediaFailed} from "../model/recipe";
import {SaveRecipePayload, UploadImagePayload} from "../reducers/RecipesReducer";


const RecipesApi = {

    async getRecipes(): Promise<Array<Recipe>> {
        let recipes = await axios.get("/recipes");
        return recipes.data;
    },

    async getRecipeById(id: String): Promise<Recipe> {
        let recipes = await axios.get("/recipes/" + id);
        return recipes.data;
    },

    async saveRecipe(payload: SaveRecipePayload): Promise<Recipe> {

        if (payload.recipe.id) {
            let updatedRecipe = await axios.put(`/recipes/${payload.recipe.id}`, payload.recipe)
            return updatedRecipe.data

        } else {

            let newRecipe = await axios.post("/recipes", payload.recipe)
            return newRecipe.data
        }
    },

    async uploadRecipeMedias(recipeId: String, payload: Array<UploadImagePayload>): Promise<Array<Media>> {

        let requests = payload.map(image => this.uploadImage(recipeId, image))
        let responses = await axios.all(requests)

        return responses.map(response => {
            switch (response.status) {
                case 200:
                    console.log("response.data : " + JSON.stringify(response.data))
                    return response.data.item
                default:
                    return null
            }
        }).filter(r => r !== null)
    },

    uploadImage(recipeId: String, payload: UploadImagePayload): Promise<Boolean> {
        return axios.post(`/recipes/${recipeId}/image/${payload.type}`, payload.formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    }

}

export default RecipesApi
