import {Media, MediaType, Recipe} from "../model/recipe";
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {RecipesApi} from "../api";

export interface RecipesState {
    loading: Boolean;
    errorMessage: String
    recipes: Array<Recipe>
}

export interface SaveRecipePayload {
    recipe: Recipe,
    images: Array<UploadImagePayload>
}


export interface UploadImagePayload {
    type: MediaType
    formData: FormData
}

const initialState: RecipesState = {
    loading: false,
    errorMessage: null,
    recipes: [],
};

export const fetchAllAsync = createAsyncThunk(
    'recipes/fetchAll',
    async () =>  await RecipesApi.getRecipes()
);

export const fetchByIdAsync = createAsyncThunk(
    'recipes/fetchById',
    async (id: String) => await RecipesApi.getRecipeById(id)
);

export const saveRecipeAsync = createAsyncThunk(
    'recipes/saveRecipe',
    async (payload: SaveRecipePayload) => {
        let savedRecipe = await RecipesApi.saveRecipe(payload)
        let imageUploadResponses = await RecipesApi.uploadRecipeMedias(savedRecipe.id, payload.images)
        console.log("savedRecipe.medias.length : " + JSON.stringify(savedRecipe.medias.length))
        savedRecipe.medias = savedRecipe.medias.map(m => {
            let updated = imageUploadResponses.find(r => {
                console.log("r : " + JSON.stringify(r))
                console.log("m : " + JSON.stringify(m))
                return r.type.toLowerCase() === m.type.toLowerCase()
            })
            console.log("updated : " + JSON.stringify(updated))
            if (updated) return updated as Media; else return m

        })

        return savedRecipe
    }
);

const recipesSlice = createSlice({
    name: "recipes",
    initialState,
    reducers: {
        addRecipe: (state, action) => {
            console.log("ADD Recipe reducer")
            state.recipes.push(action.payload)
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllAsync.pending, (state) => {
                state.loading = true
            })
            .addCase(fetchAllAsync.fulfilled, (state, action) => {
                state.loading = false
                state.recipes = action.payload;
            })
            .addCase(fetchByIdAsync.pending, (state) => {
                state.loading = true
            })
            .addCase(fetchByIdAsync.rejected, (state) => {
                state.loading = false
                state.errorMessage = "Oops ! Une erreur est survenue. Veuillez réessayer."
            })
            .addCase(fetchByIdAsync.fulfilled, (state, action) => {
                state.loading = false
                let index = state.recipes.findIndex(r => r.id === action.payload.id)
                if (index === -1) state.recipes.push(action.payload)
                else state.recipes[index] = action.payload
            })
            .addCase(saveRecipeAsync.fulfilled, (state, action) => {
                let index = state.recipes.findIndex(r => r.id === action.payload.id)
                if (index === -1) state.recipes.push(action.payload)
                else state.recipes[index] = action.payload
            })
    },
})

export const { addRecipe } = recipesSlice.actions;

export const selectRecipeById = (state, id) => state.recipes.find(r => r.id === id);

export const getRecipeById = (id: String) => (dispatch, getState) => {
    const currentValue = selectRecipeById(getState(), id);
    if (currentValue == null) {
        dispatch(fetchByIdAsync(id));
    }
    return currentValue
};

export default recipesSlice.reducer;
