const ImageUploadService = {

  uploadImage(mapping) {
    // const token = window.localStorage.getItem('@@GOOYAVE/1.0.0_auth')

    let request = new Request(`/recipes/image/upload`, {
      method: 'POST',
      body: mapping.body,
      headers: {
        // 'Authentication': `Bearer ${token}`,
        'Accept': 'application/json',
      },
    })

    return window.fetch(request)
      .then((response) => {
        if (response.ok) {
          return response.json()
        }
        return false
      }).catch((error) => {
        console.error(error)
        return false
      })
  },
}

export default ImageUploadService
