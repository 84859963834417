import { createTheme } from '@mui/material/styles';



// ;

const theme = createTheme({
    palette: {
        primary: {
            main: '#65C3E6',
            contrastText: '#fff'

        },
        secondary: {
            main: '#f64487',
            // dark: will be calculated from palette.secondary.main,
        },
    },
});

export default theme
