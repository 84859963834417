import {configureStore} from "@reduxjs/toolkit";
import thunkMiddleware from 'redux-thunk'
import loggerMiddleware from './middleware/logger'

import RecipesReducer from "../recipes/reducers/RecipesReducer.ts";

export default function configureAppStore(preloadedState) {
    return configureStore({
        reducer: {
            recipes: RecipesReducer
        },
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware()
                .concat(loggerMiddleware)
                .concat(thunkMiddleware),
        preloadedState,
    })
}
