import * as React from 'react'

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import Menu from '@mui/material/Menu';
import {AppBar, Fab, Toolbar, Typography, MenuItem, Tabs, Tab} from "@mui/material";
import {styled} from "@mui/material/styles";
import {useNavigate, useLocation} from "react-router-dom";

const logo = require('../images/toute_douce_cookiing_pink.png')
interface LinkTabProps {
    label?: string;
    href?: string;
}
function LinkTab(props: LinkTabProps) {
    return (
        <Tab
            component="a"
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                event.preventDefault();
            }}
            {...props}
        />
    );
}


export default function Header() {

    const navigate = useNavigate()
    const location = useLocation();

    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };



    const StyledFab = styled(Fab)({
        position: 'absolute',
        zIndex: 1,
        top: 60,
        right: 30,
        margin: '0 auto',
    });



    const StyledToolbar = styled(Toolbar)(({ theme }) => ({
        alignItems: 'center',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2),
    }));

    const createRecipe = () => navigate(`/recipes/create`)

    return (
        <AppBar position="relative" className="App-header">
            <StyledToolbar>
                <img src={logo} className="App-logo" alt="logo" style={{marginRight: '1em'}}/>
                <Typography variant="h5" noWrap component="a" href="/"
                            sx={{
                                mr: 2,
                                display: { md: 'flex' },
                                fontFamily: 'Avenir',
                                fontWeight: 700,
                                letterSpacing: '.2rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}>Toute Douce&nbsp;<br/><div style={{color: "white"}}>Cooking</div></Typography>

            </StyledToolbar>
            <StyledFab color="secondary" aria-label="add" onClick={createRecipe}>
                <AddIcon />
            </StyledFab>
        </AppBar>
    )
}

function openSearchBar() {
    this.setState({searchBarOpen: !this.state.searchBarOpen})
}
