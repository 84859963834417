import * as React from 'react'
import cx from 'classnames'

export interface Props {
    type: String,
    size: 'lg' | '2x' | '3x' | '4x' | '5x',
    animation: 'spin' | 'pulse',
    fullWidth: Boolean,
    rotate: 90 | 180 | 270,
    flip: 'horizontal' | 'vertical',
    style: Object
}

export function Icon(props: Props) {
    const className = cx(
        'fas', `fa-${props.type}`,
        props.fullWidth && 'fa-fw',
        props.animation && `fa-${props.animation}`,
        props.size && `fa-${props.size}`,
    )

    return <i className={className} style={props.style}/>
}
