import * as React from 'react';
import {connect} from 'react-redux';

import {fetchAllAsync} from '../reducers/RecipesReducer.ts'

import Masonry from '@mui/lab/Masonry';
import pateJPEG from "../../images/pates.jpg"

import './RecipeList.css'
import {Box, Card, CardContent, CardMedia, Skeleton, Typography} from "@mui/material";
import {compose} from "redux";
import {withParams} from "../../views/RouterHooks";

class Recipes extends React.Component {

    async componentDidMount() {
        this.props.fetchAll()
    }

    render() {
        let recipesTags = this.renderRecipes(this.props.recipes)
        return (
            <div className="page page-content in-column">
                <h1>Toutes les recettes</h1>
                <div className="recipe-list-content">
                    {this.props.errorMessage ? (
                        <div><h2>{this.props.errorMessage}</h2></div>
                    ): <Masonry columns={3} spacing={4}>{recipesTags}</Masonry>}
                </div>
            </div>
        )
    }

    renderRecipes(recipes) {
        if (!recipes || this.props.loading) return Array.from(new Array(10)).map((_, i) => {
            return (
                <Box key={"recipe-" + i}>
                    <Skeleton variant="rectangular" height={250} />
                    <Box sx={{ pt: 0.5 }}>
                        <Skeleton />
                        <Skeleton width="60%" />
                    </Box>
                </Box>
            )
        });

        return recipes.map((recipe, i) => {
            let url = recipe.medias && recipe.medias.length > 0 ? recipe.medias[0].url : pateJPEG

            return <Card key={"recipe-" + i} onClick={this.createRecipe.bind(this, recipe.id)}
                         style={{cursor: 'pointer'}}>
                <CardMedia component="img" image={url} alt={recipe.name}/>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {recipe.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Concocté par {recipe.madeBy}
                    </Typography>
                </CardContent>

            </Card>
        })
        //return <ul className="recipe-list">{result}</ul>
    }

    createRecipe(recipeId) {
        this.props.navigate(`/recipes/${recipeId}`)
    }
}

const mapStateToProps = (state) => {
    return {
        recipes: state.recipes.recipes,
        loading: state.recipes.loading,
        errorMessage: state.recipes.errorMessage
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchAll: () => { dispatch(fetchAllAsync())}
    }
};

export default compose(withParams, connect(mapStateToProps, mapDispatchToProps))(Recipes)

