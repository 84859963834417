import React from 'react'
import {Icon} from './Icon'
import ImageUploadService from '../../services/ImageUploadService'
import EditIcon from '@mui/icons-material/Edit'
import PublishIcon from '@mui/icons-material/Backup';

import './ImageLoader.css'

class ImageLoader extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            elementId: 'imagePreview-' + this.props.id,
            fileChoose: this.props.filePath !== undefined && this.props.filePath !== ''
        }
        this.fileInput = React.createRef()
    }

    render() {
        //this.drawThumbnail(this.state.filePath, `imagePreview-${this.props.id}`)
        let statusIcon = null
        if (this.state.status === 'success') {
            statusIcon = <Icon type="check" fullWidth={true}/>
        } else if (this.state.status === 'error') {
            statusIcon = <Icon type="times" fullWidth={true}/>
        } else if (this.state.status === 'uploading') {
            statusIcon = <Icon type="spinner" animation="spin"/>
        }

        return  <div className="image-selection-preview">
            <img id={`imagePreview-${this.props.id}`} className="image-preview" src={this.props.existingImage}/>
            <input className="inputFile" ref={this.fileInput} id={`${this.props.id}`} type="file"
                   onChange={this.handleFile.bind(this)}/>

            {
                !this.state.fileChoose
                    ? (<label htmlFor={`${this.props.id}`}>
                        <PublishIcon /><label>{this.props.label}</label>
                    </label>)
                    : <label style={{position: 'absolute', right: '0'}} htmlFor={`${this.props.id}`}>
                        <EditIcon className="edit-icon"/>
                    </label>
            }

            <div className="">{statusIcon}</div>
        </div>
    }

    drawThumbnail(filePath, elementId) {
        const image = new Image()
        image.src = filePath

        image.onload = function () {
            let maxWidth = 1000,
                maxHeight = 1000,
                imageWidth = image.width,
                imageHeight = image.height

            if (imageWidth > imageHeight) {
                if (imageWidth > maxWidth) {
                    imageHeight *= maxWidth / imageWidth
                    imageWidth = maxWidth
                }
            } else {
                if (imageHeight > maxHeight) {
                    imageWidth *= maxHeight / imageHeight
                    imageHeight = maxHeight
                }
            }

            const canvas = document.createElement('canvas')
            canvas.width = imageWidth
            canvas.height = imageHeight
            image.width = imageWidth
            image.height = imageHeight
            const ctx = canvas.getContext('2d')
            ctx.drawImage(this, 0, 0, imageWidth, imageHeight)

            let url = filePath.indexOf('http') !== -1
                ? filePath
                : canvas.toDataURL()

            let imagePreview = document.getElementById(elementId);
            if (imagePreview) imagePreview.src = url
        }
    }

    handleFile(e) {
        e.preventDefault()
        this.setState({fileChoose: true})

        const file = this.fileInput.current.files[0]
        const reader = new FileReader()

        reader.onload = function (event) {
            this.drawThumbnail(event.target.result, `imagePreview-${this.props.id}`)
            this.props.callback({fileName: file.name, url: "tmp"})
        }.bind(this)

        if (file) {
            reader.readAsDataURL(file)
        }
    }
}

export default ImageLoader
