import { Box } from "@mui/material";

import './Block.scss'
const Block = function(props) {
    return (
        <Box className="block" style={Object.assign(props.style, {width: '100%'})}>
            {BlockTitle(props.title)}
            {props.children}
        </Box>
    )
}

function BlockTitle(title) {
    return title
        ? <div className="block-header"><h2>{title}</h2></div>
        : <div className="block-header empty" />
}

export default Block
