import {Route, Routes} from 'react-router-dom'
import Header from "./components/Header";
import Recipes from "./recipes/views/Recipes"
import Recipe from "./recipes/views/Recipe"
import RecipeForm from "./recipes/views/RecipeForm";

import './App.css';
import './styles/flexUtils.css';
import './styles/page.scss';
import theme from './styles/themes'
import {ThemeProvider} from "@mui/material";

function App() {
  return (
      <ThemeProvider theme={theme}>
          <div className="App">
              <Header />
              <main className="App-content">
                  <Routes>
                      <Route path='/' element={<Recipes/>}></Route>
                      <Route path='/recipes' element={<Recipes/>}></Route>
                      <Route path="/recipes/create" element={<RecipeForm />}/>
                      <Route path="/recipes/:id" element={<Recipe />}/>
                      <Route path="/recipes/:id/edit" element={<RecipeForm />}/>
                      {/*<Route exact path='/' component={Suggestions}/>*/}
                      {/*<Route path='/cleaning-recipes' component={CleaningRecipes} />*/}
                      {/*<Route path='/admin' component={LoginPage} />*/}
                  </Routes>

              </main>
          </div>
      </ThemeProvider>

  );
}

export default App;
