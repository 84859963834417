import React from 'react'
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';

const OnlyNumbers = new RegExp(/^\d+$/)

class Field extends React.Component {

    constructor(props) {
        super(props);
        this.fieldRef = React.createRef();
    }

    onChange(event) {
        event.preventDefault()
        let value = this.props.type === 'number' ? parseInt(event.target.value) : event.target.value
        this.props.onChange(this.props.name, value)
    }

    render() {
        return <TextField label={this.props.label}
                          error={this.props.isInError}
                          helperText={this.props.isInError ? this.props.errorText : null}
                       ref={this.fieldRef}
                       variant={this.props.variant}
                       type={this.props.type}
                       value={this.props.value}
                       fullWidth={this.props.fullWidth}
                       rows={this.props.rows}
                       maxRows={this.props.rows}
                       onKeyDown={this.props.onKeyDown}
                       multiline={this.props.multiline}
                       aria-errormessage={this.props.isInError ? this.props.errorText : null}
                       onChange={this.onChange.bind(this)} />
    }
}

Field.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string,
    type: PropTypes.oneOf(["text", "number"]),
    errorText: PropTypes.string,
    multiline: PropTypes.bool,
    isInError: PropTypes.bool.isRequired,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    variant: PropTypes.oneOf(["outlined", "standard"]),
    fullWidth: PropTypes.bool
};

Field.defaultProps = {
    multiline: false,
    isInError: false,
    type: 'text',
    variant: 'outlined',
    fullWidth: true
}

export default Field
